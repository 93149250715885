<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
  >
    <v-card>
      <v-card-title
        class="full-width-card-title"
      >
      <v-icon flat @click="clicked">close</v-icon>View Details
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="fields.username" v-model="user.userId" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="fields.messageId" v-model="model.messageId" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="fields.transactionUuid"
                    v-model="model.transactionUuid" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="fields.createdDate" v-model="model.createdDate" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-textarea outline rows="30" v-bind="fields.beResponse"
                    v-model="prettyJson" v-if="roleAtLeastTa"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="fields.postBackUrl" v-model="model.postBackUrl" />
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { fields } from '@/components/config/userAlerts';

export default {
  name: 'AlertDetailsDialog',

  props: {
    open: Boolean,
    disabled: Boolean,
    model: Object,
    user: Object,
  },
  data: () => ({
    fields,
    isOpen: false,
  }),
  computed: {
    ...mapGetters('app', ['roleAtLeastSuper', 'roleAtLeastTa']),
    prettyJson() {
      let message = '';
      if (this.model.beResponse) {
        const str = this.model.beResponse;
        let jobj = {};
        try {
          jobj = JSON.parse(this.model.beResponse);
        } catch (e) {
          return str;
        }
        message = JSON.stringify(jobj, null, 2);
        // return JSON.stringify(jobj, null, 2);
      } else if (this.model.exception) {
        message = this.model.exception;
      }
      if (this.model.antispoofResponse) {
        // Append antispoofResponse data.
        const asData = JSON.stringify(JSON.parse(this.model.antispoofResponse), null, 2);
        message += '\n-----Anti-Spoof Response-----\n';
        message += asData;
      }
      return message;
    },
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
  },
  methods: {
    clicked() {
      this.isOpen = false;
      this.$emit('clicked');
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
  },
};
</script>
